@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./_ckColors.css');

/* width */

::-webkit-scrollbar {
    width: 0px;
}


/* Track */

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 0px;
}

:root {
    --color-primary: #f3a952;
    --color-primary-20: rgba(243, 169, 82, 0.2);
    --color-secondary: #8b8aa2;
    --color-transparent-w-10: rgba(255, 255, 255, 0.1);
    --color-transparent-w-20: rgba(255, 255, 255, 0.2);
    --color-transparent-w-30: rgba(255, 255, 255, 0.3);
    --color-transparent-b-50: rgba(0, 0, 0, 0.5);
    --color-transparent-b-60: rgba(0, 0, 0, 0.6);
    --color-transparent-b-70: rgba(0, 0, 0, 0.7);
    --color-transparent-b-80: rgba(0, 0, 0, 0.8);
    --color-bg: #1c1a23;
    --color-bg-menu: #070707;
    --color-bg-200: #14141d;
    --color-bg-300: #24242f;
    --color-bg-400: rgba(243, 169, 82, 0.1);
    --color-clear: transparent;
    --size-xs: 4px;
    --size-s: 8px;
    --size-m: 16px;
    --size-l: 32px;
    --size-xl: 64px;
    --default-shadow: 0px 3px 20px rgba(0, 0, 0, 0.13);
    --gradient: linear-gradient(360deg, #1f1c2b, rgba(31, 28, 43, 0));
    --gradient-r: linear-gradient(180deg, #1f1c2b, rgba(31, 28, 43, 0));
    --gradient-half: linear-gradient(360deg, #1f1c2b, #1f1c2b 40%, rgba(31, 28, 43, 0));
}

body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::-webkit-scrollbar {
    display: none;
}

input,
textarea {
    outline: none;
    caret-color: var(--color-primary);
    color: #fff;
}

.navbar {
    mask-image: linear-gradient(180deg, #000, transparent);
}


/* .react-switch-bg {
	background-color: #00000040 !important;
} */

.slick-active div {
    opacity: 1;
}

.firebase-emulator-warning {
    display: none;
}

body {
    overflow: hidden;
}